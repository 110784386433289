<template>
    <div class="mm-container inner-container">
        <div class="inner-left-col">
            <sidebar />
        </div>
        <div class="inner-right-col">
            Connect Page
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';

export default {
    name: 'ConnectWithMe',
    components: {
        Sidebar
    }
}
</script>