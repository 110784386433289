<template>
	<div class="sidebar">
		<div class="profil-pic">
			<img src="../img/murtaza-malek.png" alt="Murtaza Malekwala">
		</div>
		<div class="name">
			<h3>Murtaza Malek</h3>
		</div>
		<div class="nav-items">
            <router-link to="/"><span>Home</span></router-link>
            <router-link to="/about-me"><span>About Me</span></router-link>
            <!-- <router-link to="/what-i-do"><span>What I Do</span></router-link>
            <router-link to="/connect-with-me"><span>Connect</span></router-link> -->
        </div>
        <div class="social">
        <a href="https://www.linkedin.com/in/murtazamalek/" target="_blank">
            <img src="../img/linkedin.svg" alt="Linked In">
        </a>
        <a href="https://www.facebook.com/murtazamalek" target="_blank">
            <img src="../img/facebook.svg" alt="Facebook">
        </a>
        <a href="https://twitter.com/murtazamalek" target="_blank">
            <img src="../img/twitter.svg" alt="Twitter">
        </a>
        <a href="https://github.com/murtazamalek" target="_blank">
            <img src="../img/github.svg" alt="Github">
        </a>
        </div>
	</div>
</template>

<script>
export default {
    name: 'Sidebar'
}
</script>